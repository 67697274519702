@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url('react-big-calendar/lib/css/react-big-calendar.css');
@import url('https://fonts.googleapis.com/css2?family=Charm&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto", sans-serif;
}

:root {
  --theme-color: #00abe4;
  --secondary-color: #1e375a;
  --para-color: #81868a;
  --dash-text-color: #526484;
  --dash-heading: #364a63;
  --body-color: #f7f7f7;
  --bg-dark-blue: #0f256e;
  --bg-light-blue: #204ecf;
  --golden: #c29d00;
  --para2: #415674;
}

/*
@media (max-width: 639px) {
  .hero-container {
    height: 56vh;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .hero-container {
    height: 56vh;
   background-color: aqua; 
  }
}

@media (min-width: 1024px) {
}

*/